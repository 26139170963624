import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SecurityService } from '../services/security.service';
import { ContributionsService } from '../services/contributions.service';

@Component({
  selector: 'app-foreign',
  templateUrl: './foreign.component.html',
  styleUrls: ['./foreign.component.scss']
})
export class ForeignComponent implements OnInit {

  backRoute = '/app/balance';
  nextRoute = '/app/sign';
  wowDisplay = false;
  form: FormGroup;

  constructor(private router: Router,
              private title: Title,
              private security: SecurityService,
              

    ) {
      this.title.setTitle('Onboarding Digital | Información Adicional');
      this.form = new FormGroup({
        hasPublicJobManager: new FormControl('', [Validators.required]),
        hasGreenCard: new FormControl('', [Validators.required]),
        hasNothAmericanNationality: new FormControl('', [Validators.required]),
        hasForeignTransactions: new FormControl('', [Validators.required]),
        hasForeignAccounts: new FormControl('', [Validators.required]),
        foreignAccountBank: new FormControl('', []),
        foreignAccountBankNumber: new FormControl('', []),
        foreignAccountCurrency: new FormControl('', []),
        foreignAccountType: new FormControl('', []),
        foreignAccountCountry: new FormControl('', []),
        foreignAccountCity: new FormControl('', []),
      });
    }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    } else {
      this.security.getForeign()
        .subscribe({
          next: (response: any) => {
            this.form.patchValue(response);
          }
        });
    }
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    this.router.navigateByUrl(this.nextRoute);
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  // wow() {
  //   this.wowDisplay = true;
  // }

  onSubmit() {
    if(this.form.controls.hasPublicJobManager.value == 'yes') {
      this.security.setForeningHasPublicJobManager(this.form.value)
      .subscribe({
        next: (response: any) => {
          localStorage.setItem('route', '/app/foreignCheck');
          this.router.navigateByUrl(this.nextRoute);
        }
      }
      );
      return;
    }
    this.security.setForeign(this.form.value)
      .subscribe({
        next: (response: any) => {
          this.next();
        }
      }
    );

  }
}
