<div class="px-[20px] h-[10vh] flex flex-row justify-between items-center text-white body-blue">
    <div (click)="back()">Regresar</div>
    <div class="h-[10vh] flex flex-row justify-end items-center p-0 float-right">
      <img src="assets/svg/logo-blanco.svg" class="w-[40px] h-[40px] float-right" alt="Logo Fincomercio" />
    </div>
  </div>
  <div class="h-[90vh] flex flex-col items-center pt-[60px] text-center bg-[#eee]" #appContainer>
    <div class="container">
      <div class="row">
        <div class=" flex justify-center items-center">
          <img src="assets/svg/Campana.svg" class="logo-bienvenido" alt="Logo Bienvenido" />
        </div>
          <h1 class="my-4 text-[#003085] font-bold text-[1.4em]">Paga tu ahorro</h1>
      </div>
      <div class="row">
        <div class="flex flex-col text-[12px] font-thin text-black text-start">
            <div class="flex px-3 pt-2">1. <div class="pr-l">Ve a la oficina Fincomercio más cercana. <br></div></div>
            <div class="flex px-3 pt-2">2. <div class="pr-l">Con tu número de cédula podrás realizar el pago a uno de nuestros asesores en caja        </div></div>
        </div>
      </div>
      <div class="row p-3">
        <div class="offset-md-6 col-md-5 text-end">
            <div class="row mt-2 p-2 bg-light-blue-100 rounded-md">
                <div class="bg-[#EEF8FF] h-auto w-full flex items-center text-[0.8rem]" style="border-radius: 6px;">
                  <p class="m-0 w-full text-start text-[10px] py-[12px] px-[20px]"><b>Recuerda:</b> <br>
                    Tienes hasta <b>{{stringDate}}</b> para pagar la primera cuota.
                  </p>
                </div>
              </div>
          <div class="row">
            <button type="button" (click)="next()" class="btn-custom mt-2">Consultar oficinas Fincomercio</button>
            <button type="button" (click)="finish()" class="h-10 w-full bg-white text-[#003085]  mt-2" style="    transition: background-color 0.3s ease;     border-radius: 6px;     border: 1px solid #003085;">Finalizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  