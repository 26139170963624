<form [formGroup]="form">
    <div class="mb-3">
        <label for="element" class="form-label text-[#003AA2]">¿Qué cargo ocupas? (Selecciona uno)</label>
        <input #element type="text" class="form-select" id="element" formControlName="element" aria-describedby="elementHelp" 
               (input)="filter($event)" (keydown.escape)="esc()" placeholder="Ej: Gerente de ventas" (keydown)="makeInvalid()" 
               [ngClass]="{'is-invalid': (form.get('element')?.invalid || !isValid) && (isTimeout || form.get('element')?.touched)}" (blur)="validateBlur()" >
        <div *ngIf="form.get('element')?.invalid && (isTimeout || form.get('element')?.touched)" class="text-red-500 text-[0.6em]">Por favor selecciona un elemento de la lista</div>
        <ul class="max-h-[300px] relative overflow-y-scroll  list-group mt-2" *ngIf="filtered && filtered.length > 0">
          <li class="list-group-item" *ngFor="let item of filtered" (click)="select(item.id,  item.name)">{{ item.name }}</li>
        </ul>
    </div>
</form>
  