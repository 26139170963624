import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { LocalstorageService } from '../services/localstorage.service';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QrComponent implements OnChanges {

  @Input() consultor;
  error;

  constructor(private router: Router,
              private location: Location,
              private localstorageService: LocalstorageService,
  ) {
    const phone = localstorageService.get('phone');
    const consultorName = localstorageService.get('consultorName');
    const qr = localstorageService.get('qr');
    const user = localstorageService.get('user');
    this.consultor = {
      phone: phone,
      name: consultorName,
      user: user,
      qrImage: qr,
    };

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['consultor'] && changes['consultor'].currentValue) {
      this.consultor = changes['consultor'].currentValue;
    }
  }

  getFullUrl() {
    return `${window.location.origin}${this.location.prepareExternalUrl(this.router.createUrlTree(['/consultor', this.consultor?.user]).toString())}`;
  }


  share() {
    if (navigator.share) {
        navigator.share({
            title: 'Link para invitar a la vinculación digital',
            text: '¡Te invito a hacer la vinculación digital de Fincomercio! ' + this.getFullUrl(),
            url: this.getFullUrl(),
        })
        .then()
        .catch((error) => console.error('Hubo un error al compartir', error));
    } else {
        navigator.clipboard.writeText('¡Te invito a unirte a la vinculación digital de Fincomercio!: ' + this.getFullUrl())
            .then(() => {
                this.error = 'Link copiado al portapapeles';
            })
            .catch(err => {
            });
        window.location.href = 'whatsapp://send?text=Hola!%20Te invito a unirte a la vinculación digital de Fincomercio!:%20%20' + this.getFullUrl();
    }
}


}
