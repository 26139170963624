import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListService } from '../../services/list.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-independient',
  templateUrl: './independient.component.html',
  styleUrls: ['./independient.component.scss']
})
export class IndependientComponent implements OnInit {

  indacts: any[] = [];
  codpros: any[] = [];
  form: FormGroup;
  timer: any;
  isTimeout = false;
  @Output() formValues = new EventEmitter();
  @Input() defaultValue: any = null;

  constructor(private listService: ListService) {
    this.form = new FormGroup({
      indact: new FormControl('', [Validators.required]),
      income: new FormControl('', [Validators.required]),
      codpro: new FormControl('', [Validators.required]),
    });
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 5000);
   }

  ngOnInit(): void {
    this.listService.getIndAct()
      .subscribe({
        next: (response: any) => {
          this.indacts = response.list;
          if(this.defaultValue?.indact){
            this.form.get('indact')!.setValue(this.defaultValue.indact);
            this.emitForm();
          }
        },
      });
      this.listService.getCodpro()
      .subscribe({
        next: (response: any) => {
          this.codpros = response.list;
          if(this.defaultValue?.codpro){
            this.form.get('codpro')!.setValue(this.defaultValue.codpro);
            this.emitForm();
          }
        }
      });
      if(this.defaultValue?.income){
        this.form.get('income')!.setValue(this.defaultValue.income);
        this.emitForm();
      }
  }

  setIncome(income) {
    this.form.get('income')!.setValue(income);
    this.emitForm();
  }

  changeOption() {
    this.emitForm();
  }
    
  emitForm() {

    const formValues = this.form.value;
    Object.keys(formValues).forEach(key => {
      if(formValues[key] === '') {
        formValues[key] = null;
      }
    });

    this.formValues.emit(formValues);
  }

}
