import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-studient',
  templateUrl: './studient.component.html',
  styleUrls: ['./studient.component.scss']
})
export class StudientComponent implements OnInit {

  @Output() formValues = new EventEmitter();
  @Input() defaultValue: any = null;

  ngOnInit(): void {
    if(this.defaultValue) {
      this.formValues.emit({income: this.defaultValue});
    }
  }

  checkIsValid(event) {

  }

  setIncome($event: number|null) {
    this.formValues.emit({income: $event});
  }
    

}
