import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SecurityService } from '../services/security.service';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {

  paymentMethods: any[] = [];
  filteredPaymentMethods: any[] = [];
  formControl = new FormControl();
  isValid = false;
  @Input() disabled = false;
  @Input() defaultValue: any = null;
  @Output() valueState = new EventEmitter<{ id: number | null, url: string | null, name: string | null }>();
  @ViewChild('paymentMethodInput', { static: false }) paymentMethodInput!: ElementRef;
  timer: any;
  isTimeout = false;
  disabledName: boolean;
  svgPaths = {
    PSE: '../../../../assets/svg/PSE.svg',
  };

  currentSvgPath: string = '';

  constructor(private securityService: SecurityService) {
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 5000);
  }

  ngOnInit(): void {
    
    this.securityService.getPaymentMethods().subscribe({
      next: (response: any) => {

        this.paymentMethods = response.data.filter(method => method.id !== 1);
        if (this.defaultValue) {
          this.setDefaultPaymentMethod(this.defaultValue);
        }
      }
    });

    if (this.disabled) {
      this.formControl.disable();
    }
  }

  updateSvgPath(methodName: string): string {
    if(this.svgPaths[methodName]){
      this.disabledName = false
      return this.svgPaths[methodName] || '';
    }
    this.disabledName = true
  }

  nameMethodPaymenth(methodName: string): string {
    return this.svgPaths.hasOwnProperty(methodName) ? methodName : methodName;
  }

  setDefaultPaymentMethod(defaultValue: any) {
    this.formControl.setValue(defaultValue);
    this.isValid = true;
    const paymentMethodObj = this.paymentMethods.find(method => method.id === defaultValue);
    if (paymentMethodObj && this.paymentMethodInput) {
      this.paymentMethodInput.nativeElement.value = paymentMethodObj.name;
    }
  }

  filterPaymentMethods(event: any) {
    this.isValid = false;
    const query = event.target.value.toLowerCase();
    this.filteredPaymentMethods = this.paymentMethods.filter(method => {
      return query.split(' ').every(word => method.name.toLowerCase().includes(word));
    });
  }

  getNameById(id: number): string | undefined {
    if(id){
      const paymentMethod = this.paymentMethods.find(method => method.id === id);
      return paymentMethod ? paymentMethod.name : undefined;
    }
    return 'Fincomercio'
  }

  selectPaymentMethod(idPaymentMethod: number) {
    const paymentMethodObj = this.paymentMethods.find(method => method.id === idPaymentMethod);
    this.isValid = true;
    if (paymentMethodObj) {
      this.valueState.emit({ id: idPaymentMethod, url: paymentMethodObj.url, name: this.getNameById(idPaymentMethod) });
    }
  }
}
