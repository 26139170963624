<div class="px-[20px] h-[10vh] flex flex-row justify-between items-center text-white body-blue">
  <div (click)="back()"></div>
  <div class="h-[10vh] flex flex-row justify-end items-center p-0 float-right">
    <img src="assets/svg/logo-blanco.svg" class="w-[40px] h-[40px] float-right" alt="Logo Fincomercio" />
  </div>
</div>
  <div class="h-[90vh] flex flex-col items-center justify-start text-center bg-[#eee] pt-[60px]">
    <div class="container flex flex-col justify-center items-center">
        <div class="flex flex-col justify-center items-center">
          <img src="assets/svg/PulgarArriba.svg" class="logo-felicitaciones" alt="Logo Felicitaciones" />     
          <h1 class="my-4 text-[#003085] font-bold text-[1.4em]">¡Felicitaciones!</h1>          
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center ">
            <p class="text-[12px] font-thin m-[10px]">Diste el primer paso hacia el cumplimiento de tus propósitos. En el Portal Transaccional podrás consultar el saldo y fecha de corte de tu ahorro, lo encontrarás como {{savingCategory}}.</p>
        </div>
      </div>
      <div class="row p-3">
        <div class="content">
            <p class="text-left text-[12px] m-0">Cuota: <b class="text-[#003085]">${{quota}}</b></p>
            <p class="text-left text-[12px] m-0">Duración: <b class="text-[#003085]">{{duration}} Meses</b></p>
            <p class="text-left text-[12px] m-0">Motivo: <b class="text-[#003085]">{{target}}</b></p>
            <p class="text-left text-[12px] m-0">Método de pago: <b class="text-[#003085]">Descuento nómina</b></p>
        </div>
        <div class="offset-md-6 col-md-5 text-end">
            <div class="row mt-2 p-2 bg-light-blue-100 rounded-md">
                <div class="bg-[#EEF8FF] h-auto w-full flex items-center text-[0.8rem]" style="border-radius: 6px;">
                  <p class="m-0 w-full text-start text-[10px] py-[12px] px-[20px]"> En el Portal Transaccional podrás consultar el saldo y fecha de corte de tu ahorro, lo encontrarás como {{savingCategory}}</p>
                </div>
            </div>
          <div class="row">
            <button type="button" (click)="finish()" class="btn-custom mt-2">
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  