<div class="px-3 text-white h-[10vh] flex flex-row justify-between items-center body-blue">
  <div class=""></div>
  <div class="h-[10vh] flex justify-end items-center float-right">
    <img src="assets/svg/logo-blanco.svg" class="h-[50px] float-right" alt="Logo Fincomercio" />
  </div>
</div>
 <div style="display:none;" #modal >
  <app-modal 
  [imagePath]="imagePath"
  [h2Text]="h2Text"
  [modalFinishText]="true"
  [textHeader]="noteText"
  [buttonNextText]="buttonNextText"
  [buttonBackText]="buttonBackText"
  [showTermsC] = false
  (modalState)="setModal($event)"
  [authorization] = "authorization"
  ></app-modal>
</div> 
<div style="display:none;" #infoModal>
  <app-modal
  [imagePath]="infoModalimagePath"
  [h2Text]="infoModalh2Text"
  [modalFinishText]="true"
  [textHeader]="infoModalnoteText"
  [subTextHeader]="subTextHeader"
  [buttonNextText]="infoModalbuttonNextText"
  [showTermsC] = false
  (modalState)="setInfoModal($event)"
  [authorization] = "authorization"
  [showBackButton] = "false"
  [showInfo]="false"
  ></app-modal>
</div>
<div class="h-[90vh] flex flex-col items-center text-center bg-[#eee] pt-[60px]" #appContainer>
  <div class="container">
    <div class="row">
      <div class="flex flex-col items-center justify-center">
        <img class="h-[80px] w-[80px]" src="../../../../assets/svg/Mano.svg" alt="">        
        <h1 class="my-4 text-[#003085] font-bold text-[1.4em]">¡Bienvenido a Fincomercio!</h1>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center text-[0.9rem] font-thin mb-2 text-black">
        Estamos emocionados de que hagas parte de nuestra Cooperativa.
      </div>
    </div>
    <div class="row p-3">
      <div class="offset-md-6 col-md-5 text-end">
        <div class="row">
           <button type="button" (click)="next()" class="btn-custom mt-2">Comenzar a ahorrar</button> 

          <a target="_blank" [href]="'https://api.whatsapp.com/send?phone=+57' + phone + '&text=Hola,%20estoy%20interesado%20en%20obtener%20más%20información.'" class="h-10 w-full bg-white text-[#003085] rounded-md cursor-pointer flex items-center justify-center text-[14px] no-underline transition-colors duration-300 ease-in-out font-roboto hover:bg-[#003085] mt-2" style="    border: 1px solid #003085;">
            Contactar un asesor
          </a>
        </div>
        <div class="row mt-3">
          <div class="col text-center">
            <span class="text-[#003085] underline cursor-pointer" (click)="finish()">Finalizar</span>
          </div>
        </div>
        <div class="row mt-2 p-2 bg-light-blue-100 rounded-md">
          <div class="h-auto w-full bg-[#EEF8FF] text-black rounded-md flex items-center justify-center text-xs">
            <p class="m-0 w-full text-center py-3 text-xs">Puedes descargar el soporte de tu vinculación
              <span (click)="downloadPDF()" style="color: #003085; text-decoration: underline; cursor: pointer;">aquí</span>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
