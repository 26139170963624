import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  constructor() { }

  set(item: string, consultor: string) {
    localStorage.setItem(item, consultor);
  }

  get(item: string) {
    const session = localStorage.getItem(item);
    if (!session) {
      return session;
    }
    return session;
  }

  clear() {
    localStorage.clear();
  }

}
