<div class="px-3 h-[10vh] flex flex-row justify-between items-center body-blue text-white">
    <div class="" (click)="back()">Regresar</div>
    <div class="h-[10vh] flex flex-row justify-end items-center p-0 float-right">
      <img src="assets/svg/logo-blanco.svg" class="h-[50px] float-right" alt="Logo Fincomercio" />
    </div>
  </div>
  <div class="h-[90vh] flex flex-col items-center justify-start text-center bg-[#eee]">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center my-4 text-[#003aa2] font-extrabold text-2xl">
            ¡Sigue avanzando!
        </div>
      </div>
  
      <div class="row">
        <div class="col text-start">
                    <form>
                      <app-field-number [minValidValue]="0" [defaultValue]="form.get('incomeExtraActivity')!.value" fieldLabel="Otros Ingresos" (fieldValue)="setIncomeExtraActivity($event)"></app-field-number>
                      <app-field-number [minValidValue]="0" [defaultValue]="form.get('outcome')!.value" fieldLabel="Egresos" (fieldValue)="setOutcome($event)"></app-field-number>
                      <app-field-number [minValidValue]="400000" [defaultValue]="form.get('assets')!.value" fieldLabel="Activos" (fieldValue)="setAssets($event)"></app-field-number>
                      <app-field-number [minValidValue]="100000" [defaultValue]="form.get('liabilities')!.value" fieldLabel="Pasivos" (fieldValue)="setLiabilities($event)"></app-field-number>
                    </form>
        </div>
      </div>
  
      <div class="button-next">
        <button
          type="button"
          [disabled]="form.invalid"
          class="btn btn-primary btn-custom"
          (click)="saveData()"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
  <!-- <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow> -->