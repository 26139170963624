import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Output() birthDate = new EventEmitter<string>();
  
  months: string[] = [];
  days: number[] = [];
  years:number[] = [];

  form: FormGroup;
  isValid = false;
  @Input() disabled = false;
  @Input() resetTimer = false;
  @Input() defaultDay: any = null;
  @Input() defaultMonth: any = null;
  @Input() defaultYear: any = null;
  @Input() label = '¿Cuando naciste?';
  @Input() timerMs = 5000;
  @Output() valueState = new EventEmitter<string | null>();
  @ViewChild('location') location!: ElementRef;
  timer: any;
  isTimeout = false;


  constructor() {
    const year = new Date().getFullYear()-85;
    this.form = new FormGroup({
      birthMonth: new FormControl('', Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.max(12),
      ])),
      birthDay: new FormControl('', Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.max(31),
      ])),
      birthYear: new FormControl('', Validators.compose([
        Validators.required,
        Validators.min(year),
        Validators.max(new Date().getFullYear()),
      ])),
    });
  }

  ngOnInit(): void {
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, this.timerMs);
    this.months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    this.days = Array.from({length: 31}, (_, i) => i + 1);
    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: 69 }, (_, i) => currentYear - i - 17);


    this.form.get('birthMonth')?.valueChanges.subscribe(() => {
      this.updateDays(this.form.get('birthMonth')?.value);
      this.selectDate();
    });
  
    this.form.get('birthDay')?.valueChanges.subscribe(() => {
      this.selectDate();
    });
  
    this.form.get('birthYear')?.valueChanges.subscribe(() => {
      this.updateDays(this.form.get('birthMonth')?.value);
      this.selectDate();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.defaultYear.currentValue != 0){
      if (changes.defaultDay) {
        this.form.get('birthDay')?.setValue(this.defaultDay);
      }
      if (changes.defaultMonth) {
        this.form.get('birthMonth')?.setValue(this.months[this.defaultMonth - 1]);
      }
      if (changes.defaultYear) {
        this.form.get('birthYear')?.setValue(this.defaultYear);
      }
    }
    if(this.disabled){
      this.form.get('birthDay').disable();
      this.form.get('birthMonth').disable();
      this.form.get('birthYear').disable();
    }
  }

  updateDays(month: string) {
    const birthYear = this.form.get('birthYear')?.value || new Date().getFullYear();
    const monthIndex = this.months.indexOf(month) + 1;
    const daysInMonth = new Date(Number(birthYear), monthIndex, 0).getDate();
    this.days = Array.from({length: daysInMonth}, (_, i) => i + 1);
  }

  selectDate() {
    const birthMonth = this.form.get('birthMonth')?.value;
    const birthDay = this.form.get('birthDay')?.value;
    const birthYear = this.form.get('birthYear')?.value;
  
    if (birthMonth && birthDay && birthYear) {
      this.isValid = true;
      const birthDate = new Date(Number(birthYear), Number(this.months.indexOf(birthMonth)), Number(birthDay));
      const formattedBirthDate = `${('0' + (birthDate.getMonth() + 1)).slice(-2)}/${('0' + birthDate.getDate()).slice(-2)}/${birthDate.getFullYear()}`;
  
      this.valueState.emit(formattedBirthDate);
    }
  }
}