<form [formGroup]="form">
<app-fonpen [defaultValue]="defaultValue.fonpen" (valueState)="setFonpen($event)" ></app-fonpen> 
<div class="mb-3">
    <label for="resolution" class="form-label text-[#003AA2]">Fecha de la resolución de pensión</label>
    <input formControlName="resolution" *ngIf="true" type="date" class="form-control" 
        id="resolution" aria-describedby="resolutionHelp"
        (keyup)="emitForm()" (change)="emitForm()" >
</div>  
<div class="mb-3">
    <label for="since" class="form-label text-[#003AA2]">¿Desde cuándo estás pensionado?</label>
    <input formControlName="since" type="date" class="form-control" id="since" aria-describedby="sinceHelp"
        [ngClass]="{'is-invalid': form.get('since')?.invalid && (isTimeout || form.get('since')?.touched)}"
        (keyup)="emitForm()" (change)="emitForm()" >
    <div *ngIf="form.get('since')?.invalid && (isTimeout || form.get('since')?.touched)" class="text-red-500 text-[0.6em]">
        Por favor selecciona una fecha válida.
    </div>
    <div *ngIf="(form.controls.since.errors?.pastDate || form.get('since')?.invalid) && (isTimeout || form.get('since')?.touched)"  class="text-red-500 text-[0.6em]">
        La fecha debe ser menor que la fecha actual.
    </div>
</div>  
<app-field-number [defaultValue]="defaultValue.income" [minValidValue]="400000" (fieldValue)="setIncome($event)" fieldLabel="¿Cuál es tu ingreso por la actividad económica por principal?"></app-field-number>
<div class="mb-3">
    <label for="codpro" class="form-label text-[#003AA2]">¿Cuál es tu profesión u oficio?</label>
    <select formControlName="codpro" class="form-select" id="codpro" aria-describedby="codproHelp" (change)="emitForm()"
        [ngClass]="{'is-invalid': form.get('codpro')?.invalid && (isTimeout || form.get('codpro')?.touched)}" >
        <option value="" selected>Selecciona una opción...</option>
        <option *ngFor="let item of codpros" [value]="item.id">{{ item.name }}</option>
    </select>
    <div *ngIf="form.get('codpro')?.invalid && (isTimeout || form.get('codpro')?.touched)" class="text-red-500 text-[0.6em]">Por favor selecciona un elemento de la lista</div>
</div>
</form>