<form [formGroup]="form">
    <div class="mb-3">
        <label for="location" class="form-label text-[#003AA2]">{{ label }}</label>
        <input type="text" class="form-control" #location id="location" formControlName="location" aria-describedby="locationHelp" 
          placeholder=""
          [ngClass]="{'is-invalid': !isValid && (isTimeout || form.get('location')?.touched)}"
          (blur)="validateBlur()" 
          (keydown)="makeInvalid()"
          (input)="filterLocations($event)"
          (focus)="filterLocations($event)"
          (keydown.escape)="esc()">

        <ul id="list" class="max-h-[300px] relative overflow-y-scroll list-group mt-2" *ngIf="filteredLocations && filteredLocations.length > 0">
          <li class="list-group-item" *ngFor="let location of filteredLocations" (click)="selectLocation(location.id,  location.name)">{{ location.name }}</li>
        </ul>
        <div *ngIf="!isValid && (isTimeout || form.get('location')?.touched)" class="text-red-500 text-[0.6em]">Por favor selecciona un elemento de la lista.</div>
    </div>
</form>
  