<div class="flex items-center justify-between h-[10vh] px-5 body-blue">
  <div class="text-white" routerLink="/app/services">Regresar</div>
  <img src="assets/svg/logo-blanco.svg" class="h-[50px] float-right" alt="Logo Fincomercio" />
</div>

<div class="flex flex-col items-center justify-center text-center bg-gray-200 h-[90vh]">
  <div class="container text-center">
    <div class="row">
      <div class="col d-flex justify-content-center">
        <app-card
          [icon]="icon"
          [firstLine]="firstLine"
          [secondLine]="secondLine"
        ></app-card>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <iframe *ngIf="videoUrl" width="560" height="315" [src]="videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen autoplay></iframe>
      </div>
    </div>

    <div>
      <button
        type="button"
        class="btn btn-primary"
        routerLink="/app/services"
        style="margin-top: 2em"
      >
        Continuar
      </button>
    </div>
  </div>
</div>
