import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalstorageService } from '../services/localstorage.service';
import { SecurityService } from '../services/security.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-finish-pap',
  templateUrl: './finish-pap.component.html',
  styleUrls: ['./finish-pap.component.scss']
})
export class FinishPapComponent implements OnInit {

  backRoute = '/app/pap';
  nextRoute = '';
  phone;
  timer;
  window;
  serverUrl = environment.serverUrl;
  namePAP : string;

  quota = "";
  target = "";
  duration = "";
  savingCategory = "";

  constructor(private router: Router,
              private title: Title,
              private localStorageService: LocalstorageService,
              private securityService: SecurityService
    ) {
      this.title.setTitle('Onboarding Digital | Finalizar');
      this.phone = this.localStorageService.get('phone');
    }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    
    
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    }
    await new Promise((resolve, reject) => {
      this.securityService.showPapData()
        .subscribe({
          next: async (response: any) => {
            const quota = this.formatQuota(response.data.quota )
            this.quota = quota;
            this.target = response.data.target.name;
            this.duration = response.data.duration;
            this.savingCategory = response.data.savingsCategory.productName;
            resolve(response);
          },
          error: async (error: any) => {
            reject(error);
          }
        });
    });
    // this.security.getPDF().subscribe((blob: any) => {
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement('a');
    //   a.href = url;
    //   a.download = 'vinculacionDigitalFincomercio.pdf'; // Nombre del archivo descargado
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // });
  }

  formatQuota(quota: number): string {
    return quota.toLocaleString('es-CO'); // Puedes cambiar 'es-CO' según la configuración regional que necesites
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl(this.nextRoute);
  }
    
  finish() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = 'https://www.fincomercio.com';
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  showWindow() {
    this.window.nativeElement.classList.remove('hidden');
    this.window.nativeElement.classList.add('visible');
  }

  hiddeWindow() {
    this.window.nativeElement.classList.remove('visible');
    this.window.nativeElement.classList.add('hidden');
  }

}
