import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalstorageService } from '../services/localstorage.service';
import { SecurityService } from '../services/security.service';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-pap-cash',
  templateUrl: './pap-cash.component.html',
  styleUrls: ['./pap-cash.component.scss']
})
export class PapCashComponent {

  backRoute = '/app/pap';
  nextRoute = '';
  timer;
  serverUrl = environment.serverUrl;
  createdDate = new Date();
  lastDate = new Date();
  stringDate = '';

  constructor(private router: Router,
              private title: Title,
              private localStorageService: LocalstorageService,
              private securityService: SecurityService
    ) {
      this.title.setTitle('Onboarding Digital | Pago en Efectivo');
    }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if(route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    }
    await new Promise((resolve, reject) => {
      this.securityService.showPapData()
        .subscribe({
          next: async (response: any) => {
            
            this.createdDate = new Date(response.data.createdAt);
            
  
            await new Promise((resolve, reject) => {
              this.securityService.showPapData()
                .subscribe({
                  next: async (response: any) => {
                    this.createdDate = new Date(response.data.createdAt);
            
                    this.lastDate = new Date(this.createdDate);
                    this.lastDate.setDate(this.createdDate.getDate() + 30);
                    this.stringDate = this.lastDate.toISOString().split('T')[0];
                    
                    
            
                    resolve(response);
                  },
                  error: async (error: any) => {
                    reject(error);
                  }
                });
            });
    
            resolve(response);
          },
          error: async (error: any) => {
            reject(error);
          }
        });
    });
  }

  async next() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl(this.nextRoute);
    window.location.href = 'https://www.fincomercio.com/oficinas-puntos-de-atencion-y-asesores';
  }
    
  finish() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = 'https://www.fincomercio.com';
  }

  async back() {
    // localStorage.setItem('route', this.backRoute);
    // this.router.navigateByUrl(this.backRoute);
  }
}

