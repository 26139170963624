import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ButonSoundComponent } from './components/buton-sound/buton-sound.component';
import { ButtonHelpComponent } from './components/button-help/button-help.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    ButonSoundComponent,
    ButtonHelpComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    ButonSoundComponent,
    ButtonHelpComponent
  ]
})
export class SharedModule { }
