<div class="px-3 h-[10vh] flex flex-row justify-between items-center text-white body-blue">
    <div class="" (click)="back()">Regresar</div>
    <div class="h-[10vh] flex flex-row justify-end items-center p-0 float-right">
      <img src="assets/svg/logo-blanco.svg" class="h-[50px] float-right" alt="Logo Fincomercio" />
    </div>
  </div>
  <div class="flex flex-col items-center justify-center text-center bg-[#eee]">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center my-4 text-[#003aa2] font-bold text-2xl">
            ¡Sigue avanzando!
        </div>
      </div>
  
      <div class="row">
        <div class="col">
              
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mb-3 text-start">
                <label for="hasPublicJobManager " class="form-label text-[#003AA2]">¿Administras recursos públicos o ejerces un cargo importante?</label>
                <select class="form-select" id="hasPublicJobManager" formControlName="hasPublicJobManager">
                    <option value="">Selecciona...</option>
                    <option value="no">No</option>
                    <option value="yes">Sí</option>
                </select>
            </div>
            <div class="mb-3 text-start">
                <label for="hasGreenCard" class="form-label text-[#003AA2]">¿Tienes Green Card?</label>
                <select class="form-select" id="hasGreenCard" formControlName="hasGreenCard">
                    <option value="">Selecciona...</option>
                    <option value="no">No</option>
                    <option value="yes">Sí</option>
                </select>
            </div>
            <div class="mb-3 text-start">
                <label for="hasNothAmericanNationality" class="form-label text-[#003AA2]">¿Tus padres tienen nacionalidad norteamericana?</label>
                <select class="form-select" id="hasNothAmericanNationality" formControlName="hasNothAmericanNationality">
                    <option value="">Selecciona...</option>
                    <option value="no">No</option>
                    <option value="yes">Sí</option>
                </select>
            </div>
            <div class="mb-3 text-start">
                <label for="hasForeignTransactions" class="form-label text-[#003AA2]">¿Realizas transacciones en el extranjero?</label>
                <select class="form-select" id="hasForeignTransactions" formControlName="hasForeignTransactions">
                    <option value="">Selecciona...</option>
                    <option value="no">No</option>
                    <option value="yes">Sí</option>
                </select>
            </div>
            <div class="mb-3 text-start">
                <label for="hasForeignAccounts" class="form-label text-[#003AA2]">¿Tienes cuentas en el extranjero?</label>
                <select class="form-select" id="hasForeignAccounts" formControlName="hasForeignAccounts">
                    <option value="">Selecciona...</option>
                    <option value="no">No</option>
                    <option value="yes">Sí</option>
                </select>
            </div>
            <div *ngIf="form.get('hasForeignAccounts')!.value == 'yes' " class="mb-3 text-start">
                <label for="foreignAccountBank" class="form-label text-[#003AA2]">Banco de la cuenta extranjera</label>
                <input type="text" class="form-control" id="foreignAccountBank" formControlName="foreignAccountBank" />
            </div>
            <div class="mb-3 text-start" *ngIf="form.get('hasForeignAccounts')!.value == 'yes'" >
                <label for="foreignAccountBankNumber" class="form-label text-[#003AA2]">Número de cuenta bancaria extranjera</label>
                <input type="text" class="form-control" id="foreignAccountBankNumber" formControlName="foreignAccountBankNumber" />
            </div>
            <div class="mb-3 text-start" *ngIf="form.get('hasForeignAccounts')!.value == 'yes'">
                <label for="foreignAccountCurrency" class="form-label text-[#003AA2]">Moneda de la cuenta extranjera</label>
                <input type="text" class="form-control" id="foreignAccountCurrency" formControlName="foreignAccountCurrency" />
            </div>
            <div class="mb-3 text-start" *ngIf="form.get('hasForeignAccounts')!.value == 'yes'">
                <label for="foreignAccountType" class="form-label text-[#003AA2]">Tipo de cuenta extranjera</label>
                <input type="text" class="form-control" id="foreignAccountType" formControlName="foreignAccountType" />
            </div>
            <div class="mb-3 text-start" *ngIf="form.get('hasForeignAccounts')!.value == 'yes'">
                <label for="foreignAccountCountry" class="form-label text-[#003AA2]">País de la cuenta extranjera</label>
                <input type="text" class="form-control" id="foreignAccountCountry" formControlName="foreignAccountCountry" />
            </div>
            <div class="mb-3 text-start" *ngIf="form.get('hasForeignAccounts')!.value == 'yes'">
                <label for="foreignAccountCity" class="form-label text-[#003AA2]">Ciudad de la cuenta extranjera</label>
                <input type="text" class="form-control" id="foreignAccountCity" formControlName="foreignAccountCity" />
            </div>
            <div class="button-next">
            <button
            [disabled]="!form.valid"
            type="submit"
            class="btn btn-primary mb-5"

          >
            Continuar
          </button>
        </div>
          </form>
      </div>
    </div>
  </div>
  <!-- <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow> -->