<div class="flex items-center justify-center flex-col h-[40vh] text-center body-blue">
  <img src="assets/svg/logo-blanco.svg" class="w-[130px]" alt="Logo Fincomercio" />
  <div class="text-3xl font-bold">Asóciate a</div>
  <div class="text-[1.3rem] font-thin mb-4 uppercase">
    Cooperativa de ahorro y crédito fincomercio ltda.
  </div>
</div>
<ng-container *ngIf="consultor?.user">
  <div class="h-[60vh] flex flex-col items-center text-center p-3">
    <p class="text-base font-thin mb-2 text-justify">
      Durante tu proceso de vinculación validaremos tu identidad con nuestro aliado Redeban-SoyYo.
    </p>
    <p class="text-base font-thin mb-2 text-justify">
      Recibirás mensajes para acompañarte durante el proceso. Por favor ten a la mano tu documento de identidad. Si estás listo, por favor presiona el botón "Iniciar"
    </p>
      <button type="button" class="btn btn-primary btn-custom" (click)="next()">
        Iniciar
      </button>

  </div>
  <!-- <button (click)="fireConfetti()">Disparar confeti</button> -->
  <!-- <img src="assets/treasure.png" id="treasure"> -->
  <!-- <app-footer class="mt-auto"></app-footer> -->
</ng-container>

<ng-container *ngIf="!consultor?.user">
  <div class="h-[60vh] flex flex-col items-center text-center p-3">
    <div class="text-base font-thin mb-2 text-left">
      Por favor solicita a tu Asesor Comercial el link o su QR para poder
      continuar con tu proceso de vinculación
    </div>
    <div class="text-base font-thin mb-2 text-left">
      <a href="https://www.fincomercio.com" class="text-blue-700">Volver a www.fincomercio.com</a>
    </div>
  </div>
</ng-container>
