<form [formGroup]="form">
  <div class="mb-3">
    <label for="target" class="form-label text-[#003AA2]">{{ label }}</label>
    <select class="form-control" id="target" formControlName="target" 
      [ngClass]="{'is-invalid': !isValid && (isTimeout || form.get('target')?.touched)}" 
      (blur)="validateBlur()" (change)="selectTarget($event)">
      <option value="" disabled selected>- Ingresa el motivo de tu ahorro -</option>
      <option *ngFor="let target of targetsById" [value]="target.id">{{ target.name }}</option>
    </select>
    <div *ngIf="!isValid && (isTimeout || form.get('target')?.touched)" class="text-red-500 text-[0.6em]">Por favor selecciona un elemento de la lista</div>
  </div>
</form>
