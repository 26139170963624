import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ListService } from '../services/list.service';

@Component({
  selector: 'app-nationality',
  templateUrl: './nationality.component.html',
  styleUrls: ['./nationality.component.scss']
})
export class NationalityComponent implements OnInit, OnChanges {

  nationalities: any[] = [];
  filteredNationalities: any[] = [];
  form: FormGroup;
  isValid = false;
  @Input() disabled = false;
  @Input() resetTimer = false;
  @Input() defaultValue: any = null;
  @Input() label = 'Nacionalidad';
  @Input() timerMs = 5000;
  @Output() valueState = new EventEmitter<number | null>();
  @ViewChild('nationality') nationality!: ElementRef;
  timer: any;
  isTimeout = false;
  showDropdown = false;

  constructor(private listService: ListService) {
    this.form = new FormGroup({
      nationality: new FormControl('', []),
    });
  }
  
  ngOnInit(): void {
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, this.timerMs);
    this.listService.getNationalities()
    .subscribe({
      next: (response: any) => {
        this.nationalities = response.nationalities;
        this.filteredNationalities = [...this.nationalities];
        this.setNationalityDefaultValue(this.defaultValue);
      },
    });
    if (this.disabled) {
      this.form.get('nationality')!.disable();
    }
  }

  ngOnChanges() {
    if (this.resetTimer) {
      clearTimeout(this.timer);
      this.isTimeout = false;
      this.timer = setTimeout(() => {
        this.isTimeout = true;
      }, 5000);
    }
    if (this.defaultValue) {
      this.setNationalityDefaultValue(this.defaultValue);
    }
    if (this.disabled) {
      this.form.get('nationality')!.disable();
    }
  }

  setNationalityDefaultValue(defaultValue: any) {
    this.form.get('nationality')!.setValue(defaultValue);
    if (defaultValue) {
      this.isValid = true;
    }
    const nationalityObj = this.nationalities.find(nationality => nationality.id == defaultValue);
    if (nationalityObj) {
      this.nationality.nativeElement.value = nationalityObj.name;
    }
  }

  filterNationalities(event: any) {
    this.isValid = false;
    const query = event.target.value.toLowerCase();
    this.filteredNationalities = this.nationalities.filter(nationality => {
      let wordsArray = query.split(' ');
      let nationalityLower = nationality.name.toLowerCase();
      for (let word of wordsArray) {
        if (!nationalityLower.includes(word)) {
          return false; // Si alguna palabra no está en el nombre de la ubicación, no la incluye
        }
      }
      return true; // Si todas las palabras están en el nombre de la ubicación, la incluye
    });
    this.showDropdown = true;
  }

  selectNationality(idNationality: number, nationality: string) {
    this.form.get('nationality')!.setValue(nationality);
    this.isValid = true;
    this.filteredNationalities = [];
    this.showDropdown = false;
    this.valueState.emit(idNationality);
  }

  esc() {
    this.filteredNationalities = [];
    this.form.get('nationality')!.setValue(null);
    this.isValid = false;
    this.showDropdown = false;
    this.valueState.emit(null);
  }

  makeInvalid() {
    this.isValid = false;
    this.valueState.emit(null);
  }

  validateBlur() {
    setTimeout(() => {
      if (!this.isValid) {
        this.esc();
      }
    }, 1000);
  }

  showAllNationalities() {
    this.filteredNationalities = [...this.nationalities];
    this.showDropdown = true;
  }
}
