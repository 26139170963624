import { Component, OnDestroy } from '@angular/core';
import { SecurityService } from '../modules/vinculaciones/services/security.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-desktop-message',
  templateUrl: './desktop-message.component.html',
  styleUrls: ['./desktop-message.component.scss'],
})
export class DesktopMessageComponent implements OnDestroy {

  qrImage;
  consultor;
  suscription;
  i = 0;

  constructor(private title: Title,
              private securityService: SecurityService,) {
    this.title.setTitle('Onboarding Digital | Inicio');
    this.suscription = this.securityService.consultor
      .subscribe((consultor: any) => {
        console.log(`llamado ${this.i}`, consultor);
        this.i++;
        this.consultor = consultor;
      });
  }

  ngOnDestroy(): void {
    if(this.suscription) {
      this.suscription.unsubscribe();
    }
  }

}
