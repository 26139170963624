<div class="px-3 text-white h-[10vh] flex flex-row justify-between items-center body-blue">

    <div class="" (click)="back()">Regresar</div>
    <div class="h-[10vh] flex flex-row justify-end items-center p-0 float-right">
      <img src="assets/svg/logo-blanco.svg" class="h-[50px] float-right" alt="Logo Fincomercio" />
    </div>
  </div>
  <div class="h-[90vh] flex flex-col items-center pt-[60px] text-center bg-[#eee]">
    <div class="container">
      <div class="row">
        <div *ngIf="!isOtpValidated" class="col d-flex justify-content-center mb-4 text-[#003aa2] font-bold text-[2.1em]">
            Firma tu vinculación
        </div>
        <div *ngIf="isOtpValidated" class="col d-flex justify-content-center mb-4 text-[#003aa2] font-bold text-[2.1em]">
            La vinculación ya ha sido firmada
        </div>
      </div>
      <div *ngIf="!isOtpValidated" class="row">
        <div class="col text-center">
              
                    <form [formGroup]="form">
                        <div class="mb-3">
                            <label for="address" class="form-label text-[#555555]">Hemos enviado a tu número celular registrado un código de seis (6) digitos, ingrésalo aquí para firmar tu vinculación:</label>
                            <input formControlName="code" type="text" class="form-control" id="address" aria-describedby="addressHelp">
                            <!-- <div id="emailHelp" class="form-text">Es la mejor forma de identificarte.</div> -->
                        </div>  
                      </form>
                      <div>Podrá reenviar el código en {{ seconds }} segundos...</div>
                      <input type="button" class="btn btn-primary h-[2.5rem] w-full mt-2" value="Reenviar código" (click)="resendCode()" [disabled]="seconds > 0">

        </div>
      </div>
  
      <div class="w-full h-10 flex justify-center items-center mt-3">
        <button [disabled]="form.invalid && !isOtpValidated"
          type="button"
          class="btn btn-primary btn-custom"
          (click)="checkCode()"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
  <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>
  <app-loader *ngIf="loading"></app-loader>