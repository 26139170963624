import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, AfterViewInit, Output, ViewChild, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ListService } from '../services/list.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrls: ['./target.component.scss']
})
export class TargetComponent implements OnInit, OnChanges, AfterViewInit {

  targets: any[] = [];
  filteredTargets: any[] = [];
  targetsById: any[] = [];
  form: FormGroup;
  isValid = false;
  @Input() disabled = false;
  @Input() resetTimer = false;
  @Input() defaultValue: any = null;
  @Input() label = '';
  @Input() idCategory: number;
  @Output() valueState = new EventEmitter<{ id: number | null, name: string | null }>();
  @ViewChild('target', { static: false }) target!: ElementRef;
  timer: any;
  isTimeout = false;

  constructor(private listService: ListService) {
    this.form = new FormGroup({
      target: new FormControl('', []),
    });
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 5000);
  }

  ngOnInit(): void {
    this.listService.getTargets().subscribe({
      next: (response: any) => {
        this.targets = response.targets;
        if (this.defaultValue) {
          this.setTargetDefaultValue(this.defaultValue);
        }
      }
    });
    this.form.get('target')!.disable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.resetTimer) {
      clearTimeout(this.timer);
      this.isTimeout = false;
      this.timer = setTimeout(() => {
        this.isTimeout = true;
      }, 5000);
    }
    if (this.defaultValue && this.target) {
      this.setTargetDefaultValue(this.defaultValue);
    }
    if (this.disabled) {
      this.form.get('target')!.enable();
    } else {
      this.form.get('target')!.disable();
    }
    if (changes['idCategory']) {
      this.filterTargetsByCategory()
      
    }
    
  }

  filterTargetsByCategory(): void {
    this.targetsById = this.targets.filter(target => target.listSavingCategoryId === this.idCategory);
  }
  ngAfterViewInit() {
    if (this.defaultValue) {
      this.setTargetDefaultValue(this.defaultValue);
    }
  }

  setTargetDefaultValue(defaultValue: any) {
    this.form.get('target')!.setValue(defaultValue);
    this.isValid = true;
    const targetObj = this.targets.find(target => target.id === defaultValue);
    if (targetObj && this.target) {
      this.target.nativeElement.value = targetObj.name;
    }
  }

  filterTargets(event: any) {
    this.isValid = false;
    const query = event.target.value.toLowerCase();
    this.filteredTargets = this.targets.filter(target => {
      let wordsArray = query.split(' ');
      let targetLower = target.name.toLowerCase();
      for (let word of wordsArray) {
        if (!targetLower.includes(word)) {
          return false;
        }
      }
      return true;
    });
  }

  selectTarget(event: any) {
    const idTarget = parseInt(event.target.value, 10);
    const targetObj = this.targets.find(target => target.id === idTarget);
    this.isValid = true;
    this.form.get('target')!.setValue(idTarget);
    this.filteredTargets = [];
    if (targetObj) {
      this.valueState.emit({ id: idTarget, name: targetObj.name });
    }
  }

  esc() {
    this.filteredTargets = [];
    this.form.get('target')!.reset();
    this.isValid = false;
    this.valueState.emit({ id: null, name: null });
  }

  validateBlur() {
    setTimeout(() => {
      const selectedValue = this.form.get('target')!.value;
      const targetObj = this.targets.find(target => target.id === selectedValue);
      if (selectedValue == null || !targetObj) {
        this.esc();
      } else {
        this.isValid = true;
        this.valueState.emit({ id: selectedValue, name: targetObj.name });
      }
    }, 1000);
  }
}
