import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { LocalstorageService } from '../modules/vinculaciones/services/localstorage.service';
import { SecurityService } from '../modules/vinculaciones/services/security.service';
import { Router } from '@angular/router';

@Injectable()
export class MainInterceptor implements HttpInterceptor {
  constructor(
    private securityService: SecurityService,
    private localstorageService: LocalstorageService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // console.log('interceptor');
    if (request.url.includes('/checkSession') || request.url.includes('/getNewSession') || request.url.includes('/setConsultor')) {
      return next.handle(request);
    }
    const session = this.localstorageService.get('session');
    if (!session) {
      // console.log('NO has session interceptor');
      const consultor = this.localstorageService.get('consultor');
      return this.getSession(consultor).pipe(
        switchMap((session: any) => {
          const newRequest = request.clone({
            setHeaders: {
              'token': session,
            },
          });
          return next.handle(newRequest);
        }),
        catchError((error: any) => {
          // console.log('error', error);
          return throwError(() => {
            return new Error(error);
          });
        })
      );
    }
    // console.log('has session interceptor');
    request = request.clone({
      setHeaders: {
        'token': session,
      },
    });
    return next.handle(request);
  }

  getSession(consultor: any) {
    return new Observable((observer) => {
      this.securityService.getNewSession(consultor).subscribe({
        next: (response: any) => {
          // console.log('response', response);
          this.router.navigate(['/']);
          this.localstorageService.set('session', response.session);
          observer.next(response.session);
          observer.complete();
        },
        error: (error: any) => {
          // console.log('error', error);
          observer.error(error);
        },
      });
    });
  }
}
