<form [formGroup]="form">
<div class="mb-3">
    <label for="indact" class="form-label text-[#003AA2]">Cuéntanos más de tu actividad</label>
    <select formControlName="indact" class="form-select" id="indact" aria-describedby="indactHelp" (change)="changeOption()"
        [ngClass]="{'is-invalid': form.get('indact')?.invalid && (isTimeout || form.get('indact')?.touched)}" >
        <option value="" selected>Selecciona una opción...</option>
        <option *ngFor="let item of indacts" [value]="item.id">{{ item.name }}</option>
    </select>
    <div *ngIf="form.get('indact')?.invalid && (isTimeout || form.get('indact')?.touched)" class="text-red-500 text-[0.6em]">Por favor selecciona un elemento de la lista</div>
</div>
<app-field-number [defaultValue]="defaultValue.income" (fieldValue)="setIncome($event)" [minValidValue]="400000" fieldLabel="¿Cuál es tu ingreso por la actividad económica principal?"></app-field-number>
<div class="mb-3">
    <label for="codpro" class="form-label text-[#003AA2]">¿Cuál es tu profesión u oficio?</label>
    <select formControlName="codpro" class="form-select" id="codpro" aria-describedby="codproHelp"  (change)="changeOption()"
        [ngClass]="{'is-invalid': form.get('codpro')?.invalid && (isTimeout || form.get('codpro')?.touched)}" >
        <option value="">Selecciona una opción...</option>
        <option *ngFor="let item of codpros" [value]="item.id" >{{ item.name }}</option>
    </select>
    <div *ngIf="form.get('codpro')?.invalid && (isTimeout || form.get('codpro')?.touched)" class="text-red-500 text-[0.6em]">Por favor selecciona un elemento de la lista</div>
</div>
</form>