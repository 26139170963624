<form [formGroup]="form" class="mb-3" >
    <label for="birthday" class="form-label text-[#003AA2]">¿Cuándo naciste?</label>
    <div style="display: flex; justify-content: space-between;" 
      class="form-control"
      [ngClass]="{'is-invalid': !isValid && (isTimeout || form.get('birthMonth')?.touched || form.get('birthDay')?.touched || form.get('birthYear')?.touched)}"
      >
      <select formControlName="birthMonth" class="form-control" style="width: auto; margin-right: 1px;"
      [ngClass]="{'is-invalid': !isValid && (isTimeout || form.get('birthMonth')?.touched)}"
      >
        <option value="">Mes</option>
        <option *ngFor="let month of months" [value]="month">{{month}}</option>
      </select>
      <select formControlName="birthDay" class="form-control" style="width: auto; margin-right: 1px;"
      [ngClass]="{'is-invalid': !isValid && (isTimeout || form.get('birthDay')?.touched) }"
      >
        <option value="">Día</option>
        <option *ngFor="let day of days" [value]="day">{{day}}</option>
      </select>
      <select formControlName="birthYear" class="form-control" style="width: 80%;" placeholder="Año"
        [ngClass]="{'is-invalid': !isValid && (isTimeout || form.get('birthYear')?.touched)}">
        <option value="">Año</option>
  <option *ngFor="let year of years" [value]="year">{{ year }}</option>
</select>

    </div>
    <div *ngIf="(form.get('birthMonth')?.touched || form.get('birthDay')?.touched || form.get('birthYear')?.touched || isTimeout) && (form.get('birthMonth')?.invalid || form.get('birthDay')?.invalid || form.get('birthYear')?.invalid)" id="birthdayHelp" class="text-red-500 text-[0.6em]">
      Debes digitar una fecha de nacimiento valida.
    </div>
</form>