<form [formGroup]="form">
    <div class="mb-3">
        <label for="element" class="form-label text-[#003AA2]">¿Cuál es tu fondo de pensiones?</label>
        <input #element type="text" class="form-control" id="element" formControlName="element" aria-describedby="elementHelp" 
                [ngClass]="{'is-invalid': form.get('element')?.invalid && (isTimeout || form.get('element')?.touched)}" 
               (input)="filter($event)" (keydown.escape)="esc()" placeholder="Ej. Previsora" (keydown)="makeInvalid()" (blur)="validateBlur()" >
          <div *ngIf="(form.get('element')?.invalid || !isValid) && (isTimeout || form.get('element')?.touched)" class="text-red-500 text-[0.6em]">Por favor selecciona un elemento de la lista</div>
          <ul id="list" class="list-group mt-2 max-h-[300px] overflow-y-scroll relative" *ngIf="filtered && filtered.length > 0">
          <li class="list-group-item" *ngFor="let item of filtered" (click)="select(item.id,  item.name)">{{ item.name }}</li>
        </ul>
    </div>
</form>
  