<ng-container *ngIf="consultor?.user">
    <div class="fixed inset-0 body-blue">
    </div>

    <div class="flex flex-col justify-center items-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center text-lg z-50">
        <img src="assets/svg/logo-blanco.svg" class="w-24 h-24" alt="Logo Fincomercio" />
        <br />

        Para mejorar tu experiencia, por favor ingresa desde tu celular.
        <br />

        Escanea el siguiente código QR con tu celular.
        <br />
        <br>
        <hr class="py-2 w-full">
        <app-qr class="flex flex-col justify-center items-center"></app-qr>
    </div>
</ng-container>
