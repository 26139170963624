
<div class="flex justify-between items-center h-[10vh] p-0 px-3 text-white body-blue">
    <div (click)="back()">Regresar
    </div>
    
    <div class="flex justify-end items-center p-0 float-right h-[10vh]">
      <img src="assets/svg/logo-blanco.svg" class="h-[50px] float-right" alt="Logo Fincomercio" />
    </div>
  </div>
  <div class="flex flex-col items-center h-[90vh] text-center bg-[#eee] overflow-y-scroll">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center my-4 text-[#003aa2] font-extrabold text-2xl">
            Algo más de información...
        </div>
      </div>
  
      <div class="row">
        <div class="col text-start">
                    <form [formGroup]="form">
                        <div class="mb-3">
                          <label for="ocupation" class="form-label text-[#003AA2]">¿Cuál es tu ocupación?</label>
                          <select formControlName="asotip" class="form-select" aria-label="Ocupación" id="ocupation" (change)="changeOption()"
                              [ngClass]="{'is-invalid': form.get('asotip')?.invalid && (isTimeout || form.get('asotip')?.touched)}" >
                            <option value="" selected>Selecciona...</option>
                            <option *ngFor="let item of asotips" [value]="item.id">{{item.codefixed}}</option>
                          </select>
                          <div *ngIf="form.get('asotip')?.invalid && (isTimeout || form.get('asotip')?.touched)" class="text-red-500 text-[0.6em]">Por favor selecciona un elemento de la lista</div>
                        </div>
                        <app-independient [defaultValue]="addressData" (formValues)="setChildValues($event)" *ngIf="form.value.asotip == 1"></app-independient>
                        <app-aggrement [defaultValue]="addressData" (formValues)="setChildValues($event)" *ngIf="form.value.asotip == 2"></app-aggrement>
                        <app-pensioner [defaultValue]="addressData" (formValues)="setChildValues($event)" *ngIf="form.value.asotip == 3"></app-pensioner>
                        <app-studient [defaultValue]="addressData.income" (formValues)="setChildValues($event)" *ngIf="form.value.asotip == 4"></app-studient>
                        
                        <app-locations [defaultValue]="addressData.location" (valueState)="setLocation($event)" [resetTimer]="resetTimer"></app-locations>
                        <div class="mb-3">
                            <label for="address" class="form-label text-[#003AA2]">Dirección de Domicilio</label>
                            <input formControlName="address" type="text" class="form-control" id="address" aria-describedby="addressHelp" (keyup)="setAddress()"
                              [ngClass]="{'is-invalid': form.get('address')?.invalid && (isTimeout || form.get('address')?.touched)}" >
                            <div *ngIf="form.get('address')?.invalid && (isTimeout || form.get('address')?.touched)" class="text-red-500 text-[0.6em]">Por favor escribe una dirección válida mayor a 8 y menor a 55 carácteres</div>
                          </div>  
                        <div class="mb-3">
                            <label for="neighborhood" class="form-label text-[#003AA2]">Barrio Domicilio</label>
                            <input formControlName="neighborhood" type="text" class="form-control" id="neighborhood" aria-describedby="neighborhoodHelp" (keyup)="setNeighborhood()"
                              [ngClass]="{'is-invalid': form.get('neighborhood')?.invalid && (isTimeout || form.get('neighborhood')?.touched)}" >
                            <div *ngIf="form.get('neighborhood')?.invalid && (isTimeout || form.get('neighborhood')?.touched)" class="text-red-500 text-[0.6em]">Por favor ingresa un barrio mayor a 3 y mayor a 30 caracteres</div>
                        </div>  
                    </form>
        </div>
      </div>
  
      <div class="button-next">
        <button
          type="button"
          [disabled]="!allValid"
          class="btn btn-primary mt-2"
          (click)="saveData()"

        >
          Continuar
        </button>
      </div>
    </div>
  </div>
  
  <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow>