<div class="px-2 h-[10vh] flex flex-row justify-between items-center overflow-hidden text-white body-blue">
  <div class="back" (click)="back()">Regresar</div>
  <img src="assets/svg/logo-blanco.svg" class="h-[50px] float-right" alt="Logo Fincomercio" />
</div>
<div class="h-[90vh] flex flex-col items-center text-center bg-gray-200 overflow-y-scroll pt-4">
  <div class="container text-center">
    <div class="row">
      <div class="col mb-2 text-[#003AA2] font-extrabold text-[1.52em]">¿Qué productos te interesan?</div>
    </div>

    <div class="row">
      <div
        class="col-md-5 flex justify-center"
      >
        <app-card [state]="activeServices.includes('credito') ? 'large': 'small'" (stateOutput)="selectService($event, 'credito')" icon="assets/corp/cell.png" firstLine="Nuevo" secondLine="Crédito" link="KErDtBeih4Q"></app-card>    
      </div>
      <div class="col-md-2" ></div>
      <div
        class="col-md-5 flex justify-center"
      >
            <app-card [state]="activeServices.includes('ahorro') ? 'large': 'small'" (stateOutput)="selectService($event, 'ahorro')" icon="assets/corp/savings.png" firstLine="Nuevo" secondLine="Ahorro" link="KErDtBeih4Q"></app-card>    
      </div>
    </div>
    
    <div class="row">
      <div
        class="col-md-5 flex justify-center"
      >
        <app-card [state]="activeServices.includes('inversion') ? 'large': 'small'" (stateOutput)="selectService($event, 'inversion')" icon="assets/corp/invest.png" firstLine="Nueva" secondLine="Inversión" link="KErDtBeih4Q"></app-card>    
      </div>
      <div class="col-md-2" ></div>
      <div
        class="col-md-5 flex justify-center"
      >
            <app-card [state]="activeServices.includes('seguro') ? 'large': 'small'" (stateOutput)="selectService($event, 'seguro')" icon="assets/corp/umbrella.png" firstLine="Nuevo" secondLine="Seguro" link="KErDtBeih4Q"></app-card>    
      </div>
    </div>

    <div class="row">
      <div
        class="col-md-5 flex justify-center"
      >
        <app-card [state]="activeServices.includes('beneficios') ? 'large': 'small'" (stateOutput)="selectService($event, 'beneficios')" icon="assets/corp/tickets.png" firstLine="Nuevos" secondLine="Beneficios" link="KErDtBeih4Q"></app-card>    
      </div>
      <div class="col-md-2" ></div>
      <div
        class="col-md-5 flex justify-center"
      >
            <app-card [state]="activeServices.includes('vinculacion') ? 'large': 'small'" (stateOutput)="selectService($event, 'vinculacion')" icon="assets/corp/hands.png" firstLine="Solo" secondLine="Vinculación" link="KErDtBeih4Q"></app-card>    
      </div>
    </div>

  </div>

  <div class="w-[73%] pt-3">
    <button [disabled]="activeServices.length === 0" type="button" class="btn btn-primary w-full" (click)="next()">
      Continuar
    </button>

  </div>
</div>
<!-- <app-wow *ngIf="wowDisplay" (completed)="next()"></app-wow> -->